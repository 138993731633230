<template>
    <div class="Footer">
        <div class="FooterBox">
            <div class="MsgBox">
                <div class="MsgBox_1">
                    <div><img src="../assets/picture/logo2.svg" style="height: 64px;"></div>
                    <div class="footer-title">联系我们</div>
                    <div class="Msg_Box1Main">
                        <div class="footer-font">服务热线：18122393143</div>
                        <div class="footer-font">邮箱：junhecs@i3vsoft.com</div>
                        <div class="footer-font">总部地址：广州市越秀区沿江中路298号中区6楼自编609房</div>
                    </div>
                    <div class="Msg_Box1Pic">
                        <img src="../assets/picture/weixin.png">
                        <img src="../assets/picture/weibo.png" class="Picture">
                        <img src="../assets/picture/qq.png" class="Picture">
                    </div>
                </div>
                <div class="MsgBox_2">
                    <div class="footer-title">产品&应用</div>
                    <div class="footer-font_First">千蚁项目管理</div>
                    <div class="footer-font">千蚁研发管理</div>
                    <div class="footer-font">千蚁CRM</div>
                    <div class="footer-font">千蚁OA</div>
                    <div class="footer-font">千蚁知识库</div>
                    <div class="footer-font">千蚁族库</div>
                    <div class="footer-font">千蚁轻量化三维引擎</div>
                    <div class="footer-font">千蚁云盘</div>
                    <div class="footer-font">百雀会议</div>
                    <div class="footer-font">百雀聊天</div>
                </div>
                <div class="MsgBox_3">
                    <div class="footer-title">解决方案</div>
                    <div class="footer-font_First">互联网</div>
                    <div class="footer-font">建筑工程</div>
                    <div class="footer-font">水利工程</div>
                    <div class="footer-font">建筑设计</div>
                    <div class="footer-font">专业服务</div>
                    <div class="footer-font">路桥工程</div>
                    <div class="footer-font">教育行业</div>
                    <div class="footer-font">工程设计</div>
                </div>
                <div class="MsgBox_4-containers">
                    <div class="MsgBox_4-container-text">
                        <div class="MsgBox_4">
                            <div class="footer-title">服务支持</div>
                            <div class="footer-font_First">教学教程</div>
                            <div class="footer-font">帮助手册</div>
                            <div class="footer-font">开发者</div>
                            <div class="footer-font">更新日志</div>
                        </div>
                        <div class="MsgBox_5">
                            <div class="footer-title">关于</div>
                            <div class="footer-font_First">关于我们</div>
                            <div class="footer-font">联系我们</div>
                            <div class="footer-font">加入我们</div>
                            <div class="footer-font">合作伙伴招募</div>
                        </div>
                    </div>
                    <div class="MsgBox_4-containers-qrcode">
                        <img style="width: 130px;height: 130px; padding: 20px;" src="../assets/picture/qrcode.png" alt="qrcode">
                        <div class="MsgBox_4-containers-qrcode-path">
                            <img src="../assets/icon/path.png" alt="path">
                            <p>扫码下载teamCo APP</p>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="Footer_bottom">
                    <span class="Footer_bottomtitle1">广州君和信息技术有限公司版权所有</span>
                    <span class="Footer_bottomtitle3">友情链接</span>
                    <span class="Footer_bottomtitle2" @click="Tolink">备案号： 粤ICU备14012727号</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        Tolink() {
            window.open("https://beian.miit.gov.cn/", "_blank");
        }
    }
}
</script>

<style lang="less">
.Footer {
    width: 100%;
    height: 584px;
    bottom: 0px;
    display: flex;
    background-color: rgb(34, 34, 34);

    >.FooterBox {
        width: 1897px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        height: 584px;
        background-image: url(../assets/picture/footer.png);

        >.MsgBox {
            height: 580px;
            width: 1202px;
            padding-top: 80px;
            display: flex;
            flex-wrap: wrap;

            >.MsgBox_1 {
                width: 480px;

                >.footer-title {
                    font-size: 20px;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 1);
                    margin-top: 30px;
                }

                >.Msg_Box1Main {
                    margin-top: 16px;
                    line-height: 30px;

                    >.footer-font {
                        color: rgba(255, 255, 255, 1);
                        font-size: 14px;
                        margin-top: 10px;
                    }

                }

                >.Msg_Box1Pic {
                    margin-top: 40px;

                    >.Picture {
                        margin-left: 14px;
                    }
                }
            }

            >.MsgBox_2 {
                width: 243px;

                >.footer-font_First {
                    margin-top: 23px;
                    color: #ffffff;
                }

                >.footer-font {
                    margin-top: 10px;
                    font-size: 14px;
                    color: #ffffff;
                }

                >.footer-title {
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 10px;
                    color: #ffffff;
                }
            }

            >.MsgBox_3 {
                width: 197px;

                >.footer-font_First {
                    margin-top: 23px;
                    color: #ffffff;
                }

                >.footer-font {
                    color: #ffffff;
                    font-size: 14px;
                    margin-top: 10px;
                }

                >.footer-title {
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 10px;
                    color: #ffffff;
                }
            }

            >.MsgBox_4-containers {
                width: 262px;
                display: flex;
                flex-direction: column;

                >.MsgBox_4-container-text {
                    display: flex;
                    justify-content: space-between;

                    >.MsgBox_4 {
                        width: 197px;

                        >.footer-font_First {
                            margin-top: 23px;
                            color: #ffffff;
                        }

                        >.footer-font {
                            font-size: 14px;
                            color: #ffffff;
                            margin-top: 10px;
                        }

                        >.footer-title {
                            font-size: 20px;
                            font-weight: 500;
                            margin-top: 10px;
                            color: #ffffff;
                        }
                    }

                    >.MsgBox_5 {
                        width: 85px;

                        >.footer-font_First {
                            margin-top: 23px;
                            color: #ffffff;
                        }

                        >.footer-font {
                            color: #ffffff;
                            margin-top: 10px;
                            font-size: 14px;
                        }

                        >.footer-title {
                            font-size: 20px;
                            font-weight: 500;
                            margin-top: 10px;
                            color: #ffffff;
                        }
                    }
                }

                >.MsgBox_4-containers-qrcode {
                    margin-top: 20px;
                    display: flex;
                    align-items: flex-end;
                    width: 304px;
                    height: 130px;
                    border-radius: 10px;
                    background: rgba(49, 49, 49, 1);

                    >.MsgBox_4-containers-qrcode-path {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        padding-bottom: 20px;
                        >p {
                            line-height: 24px;
                            padding-top: 5px;
                            color: rgba(255, 255, 255, 0.6);
                        }
                    }
                }
            }

            

            >.Footer_bottom {
                width: 100%;

                >.Footer_bottomtitle1 {
                    float: left;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 1);
                }

                >.Footer_bottomtitle2 {
                    font-size: 12px;
                    float: right;
                    margin-right: 180px;
                    color: rgba(255, 255, 255, 1);
                    cursor: pointer;
                }

                >.Footer_bottomtitle3 {
                    font-size: 12px;
                    color: rgba(255, 255, 255, 1);
                    float: right;
                    margin-right: 20px;
                }
            }
        }
    }

    hr {
        background-color: #343434;
        height: 3px;
        width: 100%;
        border: none;
    }
}
</style>